import { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import {
  DEFAULT_RADIUS,
  SEARCH_STATE_PARAMS,
} from '@xing-com/crate-jobs-constants';
import { useFilter, useSearchState } from '@xing-com/crate-jobs-hooks';
import { NoSSR } from '@xing-com/crate-utils-no-ssr';
import type { SliderProps } from '@xing-com/slider';
import { Slider } from '@xing-com/slider';
import { BodyCopy } from '@xing-com/typography';

import { SliderWrapper } from '../shared.styles';

const MARKS = [
  { radius: '0', value: 1 },
  { radius: '10', value: 2 },
  { radius: '20', value: 3 },
  { radius: '50', value: 4 },
  { radius: '70', value: 5 },
  { radius: '100', value: 6 },
  { radius: '200', value: 7 },
];

const getRadiusValue = (sliderValue: number): string =>
  MARKS[sliderValue - 1]?.radius;

const getSliderValue = (radiusValue: string): number => {
  const index = MARKS.findIndex((mark) => mark.radius === radiusValue);
  return MARKS[index]?.value;
};

type Props = {
  className?: string;
};

export const RadiusFilter: React.FC<Props> = ({ className }) => {
  const { formatMessage } = useIntl();
  const { searchQuery } = useSearchState();
  const { value: radius, onChange } = useFilter(SEARCH_STATE_PARAMS.radius);

  const radiusValue = getSliderValue(radius ?? String(DEFAULT_RADIUS));
  const [internalSliderValue, setInternalSliderValue] = useState(radiusValue);

  useEffect(() => {
    // Check if the URL params have been changed (i.e. by resetting the filters)
    setInternalSliderValue(radiusValue);
  }, [radiusValue]);

  const { text: locationText } = searchQuery.location || {};
  if (!locationText) {
    return null;
  }

  const label =
    internalSliderValue === 1
      ? formatMessage(
          {
            id: 'JOBS_SEARCH_FILTER_RADIUS_SLIDER_TEXT_WEB',
          },
          {
            location: locationText,
          }
        )
      : `${getRadiusValue(internalSliderValue)} km`;

  const handleOnChange: SliderProps['onChange'] = (e, sliderValue): void => {
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    setInternalSliderValue(sliderValue as number);
  };

  const handleOnChangeCommitted: SliderProps['onChangeCommitted'] = (
    e,
    sliderValue
  ): void => {
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    onChange(getRadiusValue(sliderValue as number));
  };

  return (
    <div className={className}>
      <BodyCopy fontWeight="bold" id="radius-filter-title">
        <FormattedMessage id="JOBS_SEARCH_FILTER_RADIUS" />
      </BodyCopy>
      <NoSSR>
        <SliderWrapper>
          <Slider
            value={internalSliderValue}
            min={1}
            max={MARKS.length}
            marks={MARKS}
            onChange={handleOnChange}
            onChangeCommitted={handleOnChangeCommitted}
            aria-labelledby="radius-filter-title"
            aria-valuetext={label}
          />
        </SliderWrapper>
      </NoSSR>
      <BodyCopy fontWeight="bold" noMargin>
        {label}
      </BodyCopy>
    </div>
  );
};
