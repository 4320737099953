import { useEffect, useState } from 'react';

import { BottomSheet } from '@xing-com/bottom-sheet';
import { useMatchMedia } from '@xing-com/crate-hooks-use-match-media';
import { useSearchParameter } from '@xing-com/crate-hooks-use-search-parameter';
import { useSearchParameters } from '@xing-com/crate-hooks-use-search-parameters';
import { SMALL_SCREEN_BOTTOM_SHEET_PARAM } from '@xing-com/crate-jobs-constants';
import { useSearchQueryId, useSearchState } from '@xing-com/crate-jobs-hooks';
import {
  useSearchTracking,
  trackingFilterName,
} from '@xing-com/crate-jobs-tracking';
import { mediaTopBarVisible } from '@xing-com/layout-tokens';

import { useSearch } from '../../../hooks';

import { FilterSwitcher } from './filter-switcher';

export const FilterBottomSheet: React.FC = () => {
  const canShowBottomSheet = useMatchMedia(mediaTopBarVisible);
  const { results } = useSearch();
  const { trackPageview, trackFilterClose } = useSearchTracking();
  const { filterCount, page, sort } = useSearchState();

  const { getSearchParameter } = useSearchParameter();
  const { setSearchParameters } = useSearchParameters();
  const activeViewParam = getSearchParameter(SMALL_SCREEN_BOTTOM_SHEET_PARAM);

  const [isDirty, setIsDirty] = useState(false);
  const searchId = useSearchQueryId();
  const [initialSearchId, setInitialSearchId] = useState(searchId);
  const isNewSearch = initialSearchId !== searchId;

  const show = !!activeViewParam && canShowBottomSheet;

  useEffect(() => {
    if (show) {
      setInitialSearchId(searchId);
      setIsDirty(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show]);

  useEffect(() => {
    if (isNewSearch) {
      setIsDirty(true);
    }
  }, [isNewSearch]);

  const trackPage = (): void => {
    trackPageview({
      results,
      sort,
      page,
      filterCount,
      shouldTrackNwt: isDirty,
    });
  };

  const handleOnClose = (): void => {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const param = activeViewParam!;
    const filter = param.startsWith('all') ? 'all_filters' : param;
    const filterName = trackingFilterName[filter] || filter;

    // Nwt
    trackFilterClose(filter, 'cancel');

    // Adobe
    setSearchParameters(
      {
        [SMALL_SCREEN_BOTTOM_SHEET_PARAM]: '',
        sc_o: `jobs_search_filter_${filterName}_cancel`,
        sc_o_PropActionOrigin: `jobs_search_filter_${filterName}`,
      },
      { removeEmpty: true }
    );
    trackPage();
  };

  return (
    <BottomSheet show={show} onOutsideClick={handleOnClose}>
      <FilterSwitcher
        activeViewParam={activeViewParam}
        trackPage={trackPage}
        isNewSearch={isNewSearch}
      />
    </BottomSheet>
  );
};
