import { useIsAiSearch } from '@xing-com/crate-jobs-hooks';

import { useSearch } from '../../../hooks';
import { FilterSet } from '../filter-set';
import { RadiusFilter } from '../radius-filter';
import { ListSalaryFilter } from '../salary-filter';
import { SortFilter } from '../sort-filter';

import * as S from './all-filters.styles';

export const AllFilters: React.FC = () => {
  const isAiSearch = useIsAiSearch();

  const {
    aggregations: { filters },
    isLoading,
    results,
    previousResults,
  } = useSearch();

  if (!isLoading && !results?.aggregations) {
    return null;
  }

  return (
    <S.Container>
      <S.AppliedFilters isSmallScreen />
      {isAiSearch ? null : <SortFilter />}
      <RadiusFilter />
      {filters.map((filter) => {
        if (filter === 'salary') {
          return <ListSalaryFilter key={filter} />;
        }

        const { titleKey, propertyName } = filter;

        return (
          <FilterSet
            key={titleKey}
            loading={isLoading && !previousResults}
            propertyName={propertyName}
            filter={filter}
            isAllFilters
          />
        );
      })}
    </S.Container>
  );
};
