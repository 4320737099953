import { useAutoAnimate } from '@formkit/auto-animate/react';
import { FormattedMessage } from 'react-intl';

import type {
  ExtractedFiltersEnabledFragment,
  JobSearchFilterCollectionSplitBenefitsFragment,
} from '@xing-com/crate-jobs-graphql';
import { useResetFilters, useSearchState } from '@xing-com/crate-jobs-hooks';
import { IconReturn } from '@xing-com/icons';
import { motionTimeNumberL } from '@xing-com/tokens';

import { useSearch } from '../../../hooks';

import * as S from './applied-filters.styles';
import {
  CompanyTag,
  ExtractedLocationTag,
  FilterTag,
  LocationTag,
  SalaryTag,
} from './tags';

export type EntityIdFilterOption = Extract<
  JobSearchFilterCollectionSplitBenefitsFragment,
  { entityId: string }
>;

const isEntityIdOption = (
  option:
    | JobSearchFilterCollectionSplitBenefitsFragment
    | ExtractedFiltersEnabledFragment
): option is EntityIdFilterOption => {
  if (
    option.__typename === 'JobFilterBenefit' ||
    option.__typename === 'JobFilterProjob' ||
    option.__typename === 'JobFilterRemote' ||
    option.__typename === 'JobFilterPublishToCompany' ||
    option.__typename === 'JobFilterSalary'
  ) {
    return false;
  }

  return true;
};

type Props = {
  className?: string;
  isSmallScreen?: boolean;
};

export const AppliedFilters: React.FC<Props> = ({
  className,
  isSmallScreen,
}) => {
  const { filterCollection: selectedFilters, extractedFilters } = useSearch();
  const appliedFilters = [
    ...selectedFilters,
    ...extractedFilters.map((filter) => {
      if (!filter) return filter;
      if (
        filter.__typename === 'JobFilterSalary' &&
        typeof filter.min !== 'number' &&
        typeof filter.max !== 'number'
      ) {
        return false;
      }

      return { ...filter, isExtracted: true };
    }),
  ].filter(Boolean);

  const { searchQuery } = useSearchState();
  const onResetFilters = useResetFilters({
    origin: 'jobs_search_filter_all_filter',
    context: 'serp_all_filters',
    trackWithAdobeAction: isSmallScreen,
    extractedFilters,
  });

  const [animationContainer] = useAutoAnimate({
    duration: Number(motionTimeNumberL),
  });

  if (appliedFilters.length === 0 && !searchQuery.location?.text) {
    return null;
  }

  let hasCompanyFilter = false;

  return (
    <S.TagContainer
      id={isSmallScreen ? 'applied-filters' : 'applied-filters-sm'}
      data-testid="applied-filters"
      className={className}
      ref={animationContainer}
    >
      <LocationTag />
      {appliedFilters.map((option) => {
        if (!option) {
          return null;
        }

        if (option.__typename === 'JobFilterSalary') {
          return <SalaryTag option={option} key="salary" />;
        }

        if (option.__typename === 'JobFilterCompany') {
          const Tag = hasCompanyFilter ? null : <CompanyTag key="company" />;
          hasCompanyFilter = true;
          return Tag;
        }

        if (option.__typename === 'JobFilterLocation') {
          return (
            <ExtractedLocationTag
              option={option}
              key={`${option.__typename}-${option.location?.localizationValue}`}
            />
          );
        }

        if (isEntityIdOption(option)) {
          return (
            <FilterTag
              option={option}
              key={`${option.__typename}-${option.entityId}`}
            />
          );
        }

        return null;
      })}
      <S.ResetButton
        icon={IconReturn}
        onClick={onResetFilters}
        variant="tertiary"
        size="small"
      >
        <FormattedMessage id="JOBS_SEARCH_RESET_FILTERS_BUTTON" />
      </S.ResetButton>
    </S.TagContainer>
  );
};
