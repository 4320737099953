import type { JobRecommendationVisibleJob } from '@xing-com/crate-jobs-hooks';
import type { JobTeaserListItemProps } from '@xing-com/crate-jobs-xinglets';

import type { UseJobMatchingReasonsResult } from '../hooks';

import type { JobItemResultVisibleJob } from './is-visible-job';

export const matchJobMatchingReasons = (
  result: JobRecommendationVisibleJob | JobItemResultVisibleJob,
  ids: string[],
  mrData: UseJobMatchingReasonsResult
): JobTeaserListItemProps['matchingReasons'] | undefined => {
  const isJobResult = result.__typename === 'JobItemResult';
  if (!isJobResult) {
    return undefined;
  }

  const jobId = result.jobDetail.id;
  if (!ids.includes(jobId)) {
    return undefined;
  }

  return {
    reasons: !mrData.loading
      ? mrData.reasons.find((reason) => reason.jobPostingId === jobId)?.markdown
      : undefined,
    error: mrData.error,
  };
};
