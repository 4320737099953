import type { SVGProps } from 'react';
import { useIntl } from 'react-intl';
import styled from 'styled-components';

import { scale150 } from '@xing-com/tokens';

type Illustration = (props: SVGProps<SVGSVGElement>) => JSX.Element;

export type FeedbackVariant = 'like' | 'neutral' | 'dislike';

type Props = {
  Default: Illustration;
  Active: Illustration;
  variant: FeedbackVariant;
  forceActive?: boolean;
  onClick: (feedback: FeedbackVariant) => void;
};

const A11Y_LABEL: Record<FeedbackVariant, string> = {
  like: 'JOBS_SEARCH_AI_FEEDBACK_LIKE_A11Y_LABEL',
  neutral: 'JOBS_SEARCH_AI_FEEDBACK_NEUTRAL_A11Y_LABEL',
  dislike: 'JOBS_SEARCH_AI_FEEDBACK_DISLIKE_A11Y_LABEL',
};

export const ColorFeedback: React.FC<Props> = ({
  Default,
  Active,
  variant,
  forceActive,
  onClick,
}) => {
  const { formatMessage } = useIntl();

  const handleOnClick = (): void => {
    onClick(variant);
  };

  return (
    <ColorOnHoverContainer
      aria-label={formatMessage({ id: A11Y_LABEL[variant] })}
      $forceActive={forceActive}
      disabled={forceActive}
      onClick={handleOnClick}
    >
      <Default width={42} height={42} />
      <Active width={42} height={42} className="active" />
    </ColorOnHoverContainer>
  );
};

const ColorOnHoverContainer = styled.button<{ $forceActive?: boolean }>`
  height: ${scale150};
  width: ${scale150};
  position: relative;
  cursor: ${({ $forceActive }) => ($forceActive ? 'default' : 'pointer')};

  & .active {
    position: absolute;
    top: 0;
    left: 0;
    transition: opacity 0.2s ease-in-out;
    opacity: ${({ $forceActive }) => ($forceActive ? 1 : 0)};
  }

  &:hover .active {
    opacity: 1;
  }

  // reset button styles
  border: none;
  padding: 0;
  background: transparent;
`;
