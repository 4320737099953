import { useNavigate } from '@reach/router';
import { useState } from 'react';
import type React from 'react';

import { useLoginState } from '@xing-com/crate-hooks-use-login-state';
import { useSearchParameter } from '@xing-com/crate-hooks-use-search-parameter';
import { useSearchParameters } from '@xing-com/crate-hooks-use-search-parameters';
import {
  SEARCH_ALERTS_MODAL_PARAM,
  SEARCH_ALERTS_MODAL_TYPES,
  SEARCH_QUERY_ID_PARAM,
  SMALL_SCREEN_BOTTOM_SHEET_PARAM,
} from '@xing-com/crate-jobs-constants';
import { SearchMetadata } from '@xing-com/crate-jobs-domain-search-components-metadata';
import { SerpsMetadata } from '@xing-com/crate-jobs-domain-serps-components-metadata';
import type { SerpsRouteParams } from '@xing-com/crate-jobs-domain-serps-helpers';
import { getJobsSerpsSearchParameters } from '@xing-com/crate-jobs-domain-serps-helpers';
import { useSerpsMatch } from '@xing-com/crate-jobs-domain-serps-hooks';
import {
  useCurrentSearchAlertStatus,
  useIsAiSearch,
  useMarkSearchAlertAsVisited,
  useSearchState,
} from '@xing-com/crate-jobs-hooks';
import { ROUTES } from '@xing-com/crate-jobs-paths';
import { useSearchTracking } from '@xing-com/crate-jobs-tracking';
import {
  SearchAlertsJobPreferencesBanner,
  SearchAlertsBanner,
  SearchAlertsErrorBanner,
  SearchAlertsSearchModal,
} from '@xing-com/crate-jobs-xinglets';
import {
  useExperiment,
  useExperiments,
  useFeatures,
  useFeatureSwitch,
} from '@xing-com/hub';

import { increaseSearchCount, isVisibleJob } from '../../helpers';
import { useSearch } from '../../hooks';
import { AppliedFilters } from '../shared';

import { EmptyState } from './empty-state';
import { ErrorState } from './error-state';
import { getItemsWithBanner } from './helpers';
import { LazyLoader } from './lazy-loader';
import { ResultsHeader } from './results-header';
import { ResultsList } from './results-list';
import { ResultsSkeleton } from './results-skeleton';
import * as S from './results.styles';
import { SearchAlertsEmailBanner } from './search-alerts-email-banner';

const REPETITION_TRIGGERS = [3, 9, 16];

type LayoutWithMetadata = {
  children: React.ReactNode;
  serps: SerpsRouteParams | null;
  jobCount?: number;
};

const ResultsLayoutWithMetadata: React.FC<LayoutWithMetadata> = ({
  children,
  serps,
  jobCount,
}) => (
  <>
    {serps ? (
      <SerpsMetadata {...serps} jobCount={jobCount} />
    ) : (
      <SearchMetadata />
    )}
    {children}
  </>
);

type Props = { isStickyHeader?: boolean };
export const Results: React.FC<Props> = ({ isStickyHeader }) => {
  const navigate = useNavigate();
  const isAiSearch = useIsAiSearch();
  const { isLoggedIn, isLoggedOut } = useLoginState();
  const serps = useSerpsMatch();
  const features = useFeatures();
  const { trackPageview } = useSearchTracking();
  const { getSearchParameter } = useSearchParameter();
  const { setSearchParameters } = useSearchParameters();
  const {
    searchAlertId,
    isLoading: isSearchAlertsLoading,
    isSearchIdAnAlert,
  } = useCurrentSearchAlertStatus();
  const {
    filterCount,
    page,
    sort,
    searchQuery: currentSearchQuery,
  } = useSearchState();
  const [previousSearchId, setPreviousSearchId] = useState('');
  const isCSSAEnabled = useFeatureSwitch('enabled_jobs_sa_cs');
  useMarkSearchAlertAsVisited(searchAlertId);

  const adExperiment = useExperiment('ABACUS-478');
  const { loading: isLoadingExperiments } = useExperiments();

  const hasProJobsMembership = features.data?.hasProJobsMembership;

  const isFilterView = !!getSearchParameter(SMALL_SCREEN_BOTTOM_SHEET_PARAM);
  const { isLoading, results, refetch } = useSearch({
    onCompleted: (data) => {
      const results = data.jobSearchByQuery;

      if (!isFilterView) {
        trackPageview({
          results,
          sort,
          page,
          filterCount,
          shouldTrackNwt: true,
        });
      }

      let queryParameters: Record<string, string> =
        serps &&
        !currentSearchQuery.keywords &&
        !currentSearchQuery.location &&
        !currentSearchQuery.filter
          ? { ...getJobsSerpsSearchParameters(serps) }
          : {};

      const newSearchId = results?.searchQuery.guid;

      if (newSearchId) {
        if (previousSearchId === newSearchId) return;
        setPreviousSearchId(newSearchId);

        const keywords = results.searchQuery.body?.keywords;
        const location = results.searchQuery.body?.location;
        const canSearchBeSaved = !!(keywords || location);

        const repetitionCount = increaseSearchCount(newSearchId);
        // TODO: forcing a page refresh will not trigger the modal because the alerts are not loaded yet
        const isActiveSA =
          isSearchIdAnAlert(newSearchId) || isSearchAlertsLoading;

        const shouldShowRepeatedModal =
          canSearchBeSaved &&
          !isActiveSA &&
          REPETITION_TRIGGERS.includes(repetitionCount) &&
          !isFilterView;

        queryParameters = {
          ...queryParameters,
          ...(shouldShowRepeatedModal
            ? {
                [SEARCH_ALERTS_MODAL_PARAM]: SEARCH_ALERTS_MODAL_TYPES.REPEAT,
              }
            : {}),
          // We add the search alert query to the URL, so other components (i.e. search alerts)
          // can access it without the need of using the useSearch hook
          [SEARCH_QUERY_ID_PARAM]: newSearchId,
        };
      }

      const withQueryParameters = Boolean(Object.keys(queryParameters).length);
      if (!isLoggedOut && serps) {
        navigate(
          `${ROUTES.search}${
            withQueryParameters
              ? `?${new URLSearchParams(queryParameters).toString()}`
              : ''
          }`,
          { replace: true }
        );
      } else if (withQueryParameters) {
        setSearchParameters(queryParameters, { replace: true });
      }
    },
  });

  if (isLoading || isLoadingExperiments)
    return (
      <ResultsLayoutWithMetadata serps={serps}>
        <ResultsSkeleton />
      </ResultsLayoutWithMetadata>
    );
  if (!results?.collection)
    return (
      <ResultsLayoutWithMetadata serps={serps}>
        <ErrorState refetch={refetch} />
      </ResultsLayoutWithMetadata>
    );
  if (results.collection.length === 0)
    return (
      <ResultsLayoutWithMetadata serps={serps}>
        <EmptyState serps={serps} />
      </ResultsLayoutWithMetadata>
    );

  const showProJobsUpsellBanner = isLoggedIn && !hasProJobsMembership;

  const filteredCollection = results.collection.filter(isVisibleJob);
  // Could be that after filtering there are no results
  if (filteredCollection.length === 0)
    return (
      <ResultsLayoutWithMetadata serps={serps}>
        <EmptyState serps={serps} />
      </ResultsLayoutWithMetadata>
    );

  const slugList = filteredCollection.map((item) => item.jobDetail.slug);

  const collection = getItemsWithBanner(filteredCollection, {
    projobs: showProJobsUpsellBanner,
    aiSearch: isAiSearch,
    adExperiment,
  });
  const trackingToken = filteredCollection[0].trackingToken.split('.')[0];
  const jobCount = results.total;

  return (
    <ResultsLayoutWithMetadata serps={serps} jobCount={jobCount}>
      <SearchAlertsEmailBanner />
      <ResultsHeader
        jobCount={jobCount}
        serps={serps}
        isStickyHeader={isStickyHeader}
      />
      <S.MediaConfined>
        <AppliedFilters />
      </S.MediaConfined>
      <ResultsList
        items={collection}
        type="SEARCH"
        slugList={slugList}
        trackingToken={trackingToken}
        matchingReasons={results.matchingReasons}
      />
      <LazyLoader isSerps={Boolean(serps && isLoggedOut)} />
      {(isAiSearch && isLoggedOut) || (isAiSearch && !isCSSAEnabled) ? null : (
        <>
          <SearchAlertsSearchModal />
          <SearchAlertsJobPreferencesBanner
            itemCount={filteredCollection.length}
            trackingToken={trackingToken}
          />
          <SearchAlertsBanner />
          <SearchAlertsErrorBanner />
        </>
      )}
    </ResultsLayoutWithMetadata>
  );
};
