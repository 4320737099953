import { FormattedMessage, useIntl } from 'react-intl';

import { useLoginState } from '@xing-com/crate-hooks-use-login-state';
import {
  locationRenderer,
  useLocationAutocompletion,
} from '@xing-com/crate-jobs-domain-search-components-search-box';
import type { LocationSuggestion } from '@xing-com/crate-jobs-hooks';
import { IconArrowRight, IconLocationPin } from '@xing-com/icons';
import { LightMode } from '@xing-com/theme-mode-switcher';

import { SearchBarInput } from './input';
import * as S from './search-bar.styles';
import { InputSuggestions } from './suggestions';

const countries = ['de', 'at', 'ch'];

const locationSuggestionToString = (item: LocationSuggestion): string =>
  item.name;

type Props = {
  keywordsInput: string;
  locationInput: string;
  isLocationDisabled?: boolean;
  onChangeKeywords: (value: string) => void;
  onChangeLocation: (value: string) => void;
  onSelectLocation: (item: LocationSuggestion) => void;
  onSubmit: () => void;
};

export const SearchBar: React.FC<Props> = ({
  keywordsInput,
  locationInput,
  isLocationDisabled = false,
  onChangeKeywords,
  onChangeLocation,
  onSelectLocation,
  onSubmit,
}) => {
  const { formatMessage } = useIntl();
  const { isLoggedOut } = useLoginState();

  const { locationSuggestions, fetchLocationSuggestions } =
    useLocationAutocompletion({ countries });

  const handleOnChangeLocation = (value: string): void => {
    onChangeLocation(value);
    if (value) {
      fetchLocationSuggestions(value.trim());
    }
  };

  const handleOnSubmit = (event: React.FormEvent<HTMLFormElement>): void => {
    event.preventDefault();
    onSubmit();
  };

  const onMouseDown = (event: React.MouseEvent<HTMLButtonElement>): void => {
    // Prevents the textarea from shrinking (losing focus) when the button is clicked, so we can perform a search
    event.preventDefault();
  };

  return (
    <LightMode>
      <S.Form
        onSubmit={handleOnSubmit}
        // FIXME: action="." is needed to show the search button on the iOS keyboards: https://stackoverflow.com/a/26287843}
        action="."
      >
        {!isLoggedOut ? (
          <InputSuggestions
            defaultValue={keywordsInput}
            onChangeKeywords={onChangeKeywords}
            onEnterPress={onSubmit}
          />
        ) : (
          <SearchBarInput
            defaultValue={keywordsInput}
            onChangeKeywords={onChangeKeywords}
            onEnterPress={onSubmit}
          />
        )}
        <S.Divider />
        <S.LocationFieldsWrapper>
          {/* @ts-expect-error FIXME: SC6 */}
          <S.LocationAutoComplete
            type="search"
            variant="inputBar"
            textFieldVariant="plain"
            sizeConfined="xlarge"
            value={locationInput}
            placeholder={formatMessage({
              id: 'JOBS_FIND_JOBS_SEARCH_LOCATION_PLACEHOLDER',
            })}
            suggestions={locationSuggestions}
            onChange={handleOnChangeLocation}
            onSelect={onSelectLocation}
            itemRenderer={locationRenderer(locationInput)}
            suggestionToString={locationSuggestionToString}
            id="location"
            icon={IconLocationPin}
            disabled={isLocationDisabled}
          />
          <S.SubmitButtonSmall
            variant="tertiary"
            size="small"
            type="submit"
            icon={IconArrowRight}
            aria-label={formatMessage({ id: 'JOBS_FIND_JOBS_SEARCH_BUTTON' })}
            onMouseDown={onMouseDown}
          />
        </S.LocationFieldsWrapper>
        <S.SubmitButtonBig
          variant="tertiary"
          type="submit"
          onMouseDown={onMouseDown}
        >
          <FormattedMessage id="JOBS_FIND_JOBS_SEARCH_BUTTON" />
        </S.SubmitButtonBig>
      </S.Form>
    </LightMode>
  );
};
