import { useQuery } from '@apollo/client';
import { useRef, useEffect } from 'react';

import type { MatchingReasonsResult } from '@xing-com/crate-common-graphql-types';
import { FabiBackgroundJobStatus } from '@xing-com/crate-common-graphql-types';
import { JobMatchingReasonsQueryDocument } from '@xing-com/crate-jobs-graphql';

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const useJobMatchingReasons = (
  backgroundJobId?: MatchingReasonsResult['backgroundJobId']
) => {
  const pollingStopped = useRef(false);
  const { data, loading, stopPolling } = useQuery(
    JobMatchingReasonsQueryDocument,
    {
      variables: { backgroundJobId: backgroundJobId ?? '' },
      skip: !backgroundJobId,
      pollInterval: 1000,
    }
  );

  useEffect(() => {
    const shouldStopPolling =
      data &&
      data.fabiJobSearchMatchingReasonsPoll?.status !==
        FabiBackgroundJobStatus.InProgress &&
      !pollingStopped.current;

    if (shouldStopPolling) {
      pollingStopped.current = true;
      stopPolling();
    }
  }, [data, stopPolling]);

  return {
    loading,
    reasons:
      data?.fabiJobSearchMatchingReasonsPoll?.result?.matchingReasons ?? [],
    error: data?.fabiJobSearchMatchingReasonsPoll?.errorMessage,
  };
};

export type UseJobMatchingReasonsResult = ReturnType<
  typeof useJobMatchingReasons
>;
