/* eslint-disable */
// @ts-nocheck
import * as Types from '@xing-com/crate-common-graphql-types';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type JobMatchingReasonsQueryQueryVariables = Types.Exact<{
  backgroundJobId: Types.Scalars['String']['input'];
}>;

export type JobMatchingReasonsQueryQuery = {
  __typename?: 'Query';
  fabiJobSearchMatchingReasonsPoll?: {
    __typename?: 'FabiJobSearchMatchingReasonsPollResponse';
    status: Types.FabiBackgroundJobStatus;
    errorMessage?: string | null;
    result?: {
      __typename?: 'FabiJobSearchMatchingReasonsResult';
      matchingReasons: Array<{
        __typename?: 'FabiJobSearchMatchingReason';
        jobPostingId: string;
        markdown: string;
        trackingToken: string;
      }>;
    } | null;
  } | null;
};

export const JobMatchingReasonsQueryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'JobMatchingReasonsQuery' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'backgroundJobId' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fabiJobSearchMatchingReasonsPoll' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'backgroundJobId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'backgroundJobId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'result' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'matchingReasons' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'jobPostingId' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'markdown' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'trackingToken' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'errorMessage' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  JobMatchingReasonsQueryQuery,
  JobMatchingReasonsQueryQueryVariables
>;
