import { FormattedMessage, useIntl } from 'react-intl';

import { useSearchParameter } from '@xing-com/crate-hooks-use-search-parameter';
import { SMALL_SCREEN_BOTTOM_SHEET_PARAM } from '@xing-com/crate-jobs-constants';
import type { FilterPropertyName } from '@xing-com/crate-jobs-domain-search-types';
import {
  useIsAiSearch,
  useResetFilters,
  useSearchState,
} from '@xing-com/crate-jobs-hooks';
import { useSearchTracking } from '@xing-com/crate-jobs-tracking';
import { IconFilter, IconReturn } from '@xing-com/icons';
import { Tag } from '@xing-com/tag';
import { LightMode } from '@xing-com/theme-mode-switcher';
import { spaceS } from '@xing-com/tokens';

import { useSearch } from '../../../hooks';

import { FilterBarItem } from './filter-bar-item';
import * as S from './filter-bar.styles';

const DISPLAYED_PROPERTY_NAMES: Record<FilterPropertyName, boolean> = {
  remoteOption: true,
  employmentType: true,
  careerLevel: true,
  benefitWorkingCulture: true,
  discipline: true,
  industry: false,
  benefitEmployeePerk: false,
  city: false,
  country: false,
  company: false,
  benefit: false,
};

const DISPLAYED_PROPERTY_NAMES_CS: Record<FilterPropertyName, boolean> = {
  remoteOption: true,
  employmentType: true,
  careerLevel: true,
  benefitWorkingCulture: false,
  discipline: false,
  industry: false,
  benefitEmployeePerk: false,
  city: false,
  country: false,
  company: false,
  benefit: false,
};

type Props = {
  className?: string;
};

export const FilterBar: React.FC<Props> = ({ className }) => {
  const isAiSearch = useIsAiSearch();
  const { formatMessage } = useIntl();
  const { setSearchParameter } = useSearchParameter();
  const { filterCount: manualFilterCount } = useSearchState();
  const {
    aggregations: { filters },
    extractedFilters,
  } = useSearch();
  const onResetFilters = useResetFilters({
    origin: 'jobs_search_serp',
    context: 'serp',
    extractedFilters,
  });
  const { trackFilterOpen } = useSearchTracking();

  const handleOnClick = (): void => {
    setSearchParameter(SMALL_SCREEN_BOTTOM_SHEET_PARAM, 'all');
    trackFilterOpen();
  };

  const displayedPropertyNames = isAiSearch
    ? DISPLAYED_PROPERTY_NAMES_CS
    : DISPLAYED_PROPERTY_NAMES;

  const displayedFilters = filters.filter(
    (filter) =>
      filter === 'salary' || displayedPropertyNames[filter.propertyName]
  );

  const filterCount = manualFilterCount + extractedFilters.length;
  const fixedButtonTitle = `${formatMessage({
    id: 'JOBS_SEARCH_FILTERS_ALL_HEADER',
  })}${filterCount > 0 ? ` (${filterCount})` : ''}`;

  return (
    <S.Wrapper className={className}>
      {/* styled-components break the light mode only :shrug: */}
      <LightMode style={{ width: '100%' }}>
        <S.Container $isAiSearch={isAiSearch}>
          <Tag
            hideBorder
            leadingIcon={IconFilter}
            selected={filterCount > 0}
            badgeProps={{ children: filterCount }}
            onClick={handleOnClick}
            aria-label={fixedButtonTitle}
          />
          <S.CarouselWrapper>
            <S.Carousel
              hideScrollbar
              tabIndex={-1}
              itemWidth="auto"
              itemWidthConfined="auto"
              itemSpacing={spaceS}
              itemSpacingConfined={spaceS}
            >
              {displayedFilters.map((filter) => (
                <FilterBarItem
                  key={filter === 'salary' ? 'salary' : filter.propertyName}
                  filter={filter}
                  trackClick={trackFilterOpen}
                />
              ))}
              <S.ResetButton
                icon={IconReturn}
                onClick={onResetFilters}
                size="small"
              >
                <FormattedMessage id="JOBS_SEARCH_RESET_FILTERS_BUTTON" />
              </S.ResetButton>
            </S.Carousel>
          </S.CarouselWrapper>
        </S.Container>
      </LightMode>
    </S.Wrapper>
  );
};
