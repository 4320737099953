import { useState } from 'react';
import { useIntl } from 'react-intl';

import type { InteractionTrackingEvent } from '@xing-com/crate-communication-tracking';
import { useTracking } from '@xing-com/crate-communication-tracking';
import { useJobsNwtTracking } from '@xing-com/crate-jobs-tracking';
import {
  SingleSizeIllustrationFeedbackDefaultSad,
  SingleSizeIllustrationFeedbackActiveSad,
  SingleSizeIllustrationFeedbackDefaultNeutral,
  SingleSizeIllustrationFeedbackActiveNeutral,
  SingleSizeIllustrationFeedbackDefaultHappy,
  SingleSizeIllustrationFeedbackActiveHappy,
} from '@xing-com/illustrations';

import * as S from './ai-feedback.styles';
import { ColorFeedback } from './color-feedback';
import type { FeedbackVariant } from './color-feedback';

type Props = {
  trackingToken?: string;
  onClick: (feedback: FeedbackVariant) => void;
};
type TrackingProps = { PropTrackAction: string };

export const FeedbackContent: React.FC<Props> = ({
  trackingToken,
  onClick,
}) => {
  const { formatMessage } = useIntl();
  const { track: trackAdobeEvent } =
    useTracking<InteractionTrackingEvent<TrackingProps>>();
  const trackNwtEvent = useJobsNwtTracking();
  const [showThanks, setShowThanks] = useState<FeedbackVariant | null>(null);

  const handleOnClick = (feedback: FeedbackVariant): void => {
    if (feedback !== 'dislike') {
      setShowThanks(feedback);
    }

    trackAdobeEvent({
      type: 'interaction',
      event: 'PropTrackAction',
      PropTrackAction: `jobs_search_ai_search_results_feedback_${feedback}`,
    });
    trackNwtEvent({
      eventSchema: 'aicomponent',
      page: 'jobs_search/serp',
      event: 'feedback_sent',
      feedback,
      feedbackQuestion: formatMessage({
        id: 'JOBS_SEARCH_AI_FEEDBACK_HEADLINE',
      })
        .toLowerCase()
        .replace(/ /g, '_'),
      element: 'icon',
      componentName: 'ai_search',
      componentFeedbackLevel: '2',
      trackingToken,
    });

    onClick(feedback);
  };

  const textId = showThanks
    ? 'JOBS_SEARCH_AI_FEEDBACK_THANKS'
    : 'JOBS_SEARCH_AI_FEEDBACK_HEADLINE';

  return (
    <>
      <S.Text noMargin forwardedAs="h2" tabIndex={0}>
        {formatMessage({ id: textId })}
      </S.Text>
      <S.IconsContainer>
        {!showThanks ? (
          <ColorFeedback
            Default={SingleSizeIllustrationFeedbackDefaultSad}
            Active={SingleSizeIllustrationFeedbackActiveSad}
            variant="dislike"
            onClick={handleOnClick}
          />
        ) : null}
        {!showThanks || showThanks === 'neutral' ? (
          <ColorFeedback
            Default={SingleSizeIllustrationFeedbackDefaultNeutral}
            Active={SingleSizeIllustrationFeedbackActiveNeutral}
            variant="neutral"
            forceActive={showThanks === 'neutral'}
            onClick={handleOnClick}
          />
        ) : null}
        {!showThanks || showThanks === 'like' ? (
          <ColorFeedback
            Default={SingleSizeIllustrationFeedbackDefaultHappy}
            Active={SingleSizeIllustrationFeedbackActiveHappy}
            variant="like"
            forceActive={showThanks === 'like'}
            onClick={handleOnClick}
          />
        ) : null}
      </S.IconsContainer>
    </>
  );
};
