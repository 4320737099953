import { useCallback } from 'react';

import { useSearchParameter } from '@xing-com/crate-hooks-use-search-parameter';
import {
  SEARCH_ALERTS_ERROR_TYPE,
  SEARCH_ALERTS_ERROR_BANNER,
} from '@xing-com/crate-jobs-constants';
import { useJobsNwtTracking } from '@xing-com/crate-jobs-tracking';

export const useShowSearchAlertErrorBanner = (
  type: keyof typeof SEARCH_ALERTS_ERROR_TYPE,
  nwtPage?: string
): (() => void) => {
  const { setSearchParameter } = useSearchParameter();
  const trackNwtEvent = useJobsNwtTracking();

  return useCallback(() => {
    if (type === 'CREATE' || type === 'DUPLICATED') {
      trackNwtEvent({
        eventSchema: 'basic',
        event: 'error_shown',
        element: 'search_alert',
        context: `search_alert_creation_error${type === 'DUPLICATED' ? '_exists_already' : ''}`,
        page: nwtPage,
      });
    }

    setSearchParameter(
      SEARCH_ALERTS_ERROR_BANNER,
      SEARCH_ALERTS_ERROR_TYPE[type]
    );
  }, [setSearchParameter, trackNwtEvent, type]);
};
