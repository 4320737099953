import { useIntl } from 'react-intl';
import styled from 'styled-components';

import { AiSearchBanner } from '@xing-com/crate-jobs-domain-search-ai-search-banner';
import { ROUTES } from '@xing-com/crate-jobs-paths';
import { ActionSlot } from '@xing-com/crate-layout-top-bar';
import { useExperiment } from '@xing-com/hub';
import { IconBookmark, IconFilter } from '@xing-com/icons';
import { mediaTopBarVisible } from '@xing-com/layout-tokens';
import { spaceM } from '@xing-com/tokens';

const Banner = styled(AiSearchBanner)`
  margin-right: ${spaceM};

  @media ${mediaTopBarVisible} {
    display: none;
  }
`;

const PreferencesActionSlot = styled(ActionSlot)`
  @media ${mediaTopBarVisible} {
    display: none;
  }
`;

const BannerSlot: React.FC = () => {
  const isAiSearchForced = useExperiment('ABACUS-468') === 'B';

  return isAiSearchForced ? null : (
    <Banner
      variant="small"
      nwtTracking={{
        page: 'jobs_search/serp',
      }}
      propActionOrigin="jobs_search_serp"
    />
  );
};

const PreferencesSlot: React.FC = () => {
  const { formatMessage } = useIntl();
  const label = formatMessage({ id: 'VERTICAL_NAV_JOB_PREFERENCES_LABEL' });
  const findJobsTitle = formatMessage({ id: 'JOBS_FIND_JOBS_TITLE' });
  const link = `${ROUTES.jobseekerCriteria}?backTo=${findJobsTitle}`;

  return (
    <PreferencesActionSlot
      size="small"
      showLabel
      text={label}
      icon={IconFilter}
      to={link}
    />
  );
};

const MyJobsSlot: React.FC = () => {
  const { formatMessage } = useIntl();
  const label = formatMessage({ id: 'VERTICAL_NAV_YOUR_JOBS_LABEL' });
  const link = `${ROUTES.myJobs}?sc_o=navigation_search_job_search_your_jobs&sc_o_PropActionOrigin=navigation_badge_no_badge`;

  return (
    <ActionSlot
      size="small"
      showLabel
      text={label}
      icon={IconBookmark}
      to={link}
      aria-label={label}
    />
  );
};

export const getPrimaryActions = (): React.ReactElement[] => {
  return [
    <BannerSlot key="1" />,
    <PreferencesSlot key="2" />,
    <MyJobsSlot key="3" />,
  ];
};
