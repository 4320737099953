import trimStart from 'lodash/trimStart';
import React from 'react';

import type { AdFields } from '../types';
import withTracking from '../with-tracking';

import ads from './ads';

interface SectionProps {
  sectionId: string;
  fallback?: React.ReactNode;
  isNewNC?: boolean;
  isLoggedOut?: boolean;
  onClickCallback?: () => void;
  onImpressionCallback?: () => void;
}

type Ad = React.LazyExoticComponent<React.FC<AdFields>>;
export type SectionAndAdProps = SectionProps & AdFields;

export const getAdComponent = (ads: Record<string, Ad>, adType: string): Ad => {
  const accessor: string = trimStart(adType, 'Auction');

  return ads[accessor];
};

const Section: React.FC<SectionAndAdProps> = (props: SectionAndAdProps) => {
  const { sectionId, type, fallback = <></> } = props;
  const Ad = getAdComponent(ads[sectionId], type);

  return (
    <div data-qa={sectionId} className={sectionId}>
      <React.Suspense fallback={fallback}>
        <Ad {...props} />
      </React.Suspense>
    </div>
  );
};

export default React.memo(withTracking(Section));
