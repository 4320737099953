import { useAutoAnimate } from '@formkit/auto-animate/react';
import { useEffect, useRef, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

import { XxlGapCol } from '@xing-com/crate-jobs-components-columns';
import type { FilterPropertyName } from '@xing-com/crate-jobs-domain-search-types';
import { spaceXL } from '@xing-com/tokens';
import { Headline } from '@xing-com/typography';

import { AllFilters } from '../all-filters';
import { FilterSet } from '../filter-set';
import { BottomSheetSalaryFilter } from '../salary-filter';
import { ShowResultsButton } from '../show-results-button';

import { createKeyframeEffect } from './keyframe-effect';

export type Views =
  | FilterPropertyName
  | 'salary'
  | 'all'
  | 'all-discipline'
  | 'all-industry';

const VIEW_TO_FILTER: Partial<Record<Views, React.ReactNode>> = {
  remoteOption: <FilterSet propertyName="remoteOption" />,
  employmentType: <FilterSet propertyName="employmentType" />,
  careerLevel: <FilterSet propertyName="careerLevel" />,
  discipline: <FilterSet propertyName="discipline" />,
  benefitWorkingCulture: <FilterSet propertyName="benefitWorkingCulture" />,
  benefitEmployeePerk: <FilterSet propertyName="benefitEmployeePerk" />,
  salary: <BottomSheetSalaryFilter />,
  all: (
    <>
      <Headline size="xxlarge" noMargin>
        <FormattedMessage id="JOBS_SEARCH_FILTERS_ALL_HEADER" />
      </Headline>
      <AllFilters />
    </>
  ),
  // second level filters
  'all-discipline': <FilterSet propertyName="discipline" isSecondLevel />,
  'all-industry': <FilterSet propertyName="industry" isSecondLevel />,
};

const Content = styled.div<{ $isSecondLevel: boolean }>`
  padding: ${spaceXL};
`;

type Props = {
  activeViewParam: string | null;
  trackPage: () => void;
  isNewSearch: boolean;
};

export const FilterSwitcher: React.FC<Props> = ({
  activeViewParam,
  trackPage,
  isNewSearch,
}) => {
  // we store the value in a state so the content is shown during the bottom sheet exit animation
  const [activeView, setActiveView] = useState(activeViewParam);
  const contentRef = useRef<HTMLDivElement>(null);

  // animations to slide left <-> right the content
  const [firstLevelRef] = useAutoAnimate(createKeyframeEffect('first'));
  const [secondLevelRef] = useAutoAnimate(createKeyframeEffect('second'));

  useEffect(() => {
    if (activeViewParam) {
      setActiveView(activeViewParam);

      // we want to scroll up if the user enters a second level filter
      if (contentRef.current) {
        contentRef.current.scrollIntoView();
      }
    }
  }, [activeViewParam]);

  if (!activeView) {
    return null;
  }

  const isSecondLevel = activeView.startsWith('all-');

  return (
    <Content $isSecondLevel={isSecondLevel} ref={contentRef}>
      <XxlGapCol ref={isSecondLevel ? secondLevelRef : firstLevelRef}>
        {/* eslint-disable-next-line @typescript-eslint/consistent-type-assertions */}
        {VIEW_TO_FILTER[activeView as Views]}
        <ShowResultsButton
          // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
          activeView={activeView as Views}
          isSecondLevel={isSecondLevel}
          trackPage={trackPage}
          isNewSearch={isNewSearch}
        />
      </XxlGapCol>
    </Content>
  );
};
