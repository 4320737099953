/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { useState, useRef } from 'react';

import { useTimedCallback } from '@xing-com/crate-jobs-hooks';

import { ANIMATION_CONTAINER_ID, ANIMATION_TYPE_SPEED_MS } from './constants';
import { CLASSES, VARIABLES } from './suggestions.styles';

type SplitText = {
  original: string;
  split: string[];
};
type UseAnimatedValueArgs = {
  onIterate?: (value: string, ite: number, split: string[]) => void;
  onAnimationEnd?: () => void;
};
type UseAnimatedValueReturn = {
  animate: (value: string) => void;
  cancel: () => void;
  isAnimating: boolean;
};

export const useAnimatedValue = ({
  onIterate,
  onAnimationEnd,
}: UseAnimatedValueArgs = {}): UseAnimatedValueReturn => {
  const [isAnimating, setIsAnimating] = useState(false);
  const iteration = useRef(0);
  const splitText = useRef<SplitText>({ original: '', split: [] });
  const timed = useTimedCallback({ speed: ANIMATION_TYPE_SPEED_MS });
  let containerMaxHeight: number | undefined;

  const type = () => {
    const container = document.getElementById(ANIMATION_CONTAINER_ID);
    if (!container) {
      return;
    }
    prepareContainerForAnimation(container);

    // Initially the value will be 0 as the element itself does not have the property
    // set, as it is set in the CSS id selector
    const heightValue = container.style.getPropertyValue(VARIABLES.HEIGHT);
    const initialHeight = +heightValue.slice(0, -2);
    const { split } = splitText.current;

    const value = split[iteration.current - 1];
    const newElement = document.createElement('span');
    newElement.innerHTML = value ? `${value}&nbsp;` : '';
    newElement.classList.add(CLASSES.ANIMATED_SPAN);
    container.appendChild(newElement);
    newElement.animate([{ opacity: '0' }, { opacity: '1' }], {
      duration: 250,
      easing: 'ease-in-out',
    });

    onIterate?.(value, iteration.current, split);

    const endHeight = container.scrollHeight;
    if (initialHeight !== endHeight) {
      container.style.setProperty(VARIABLES.HEIGHT, `${endHeight + 2}px`);
      if (endHeight <= (containerMaxHeight ?? 0)) {
        container.style.overflowY = 'hidden';
      } else {
        container.style.overflowY = 'auto';
      }
      // Make sure we are scrolling to bottom
      container.scrollTo({ top: container.scrollHeight, behavior: 'smooth' });
    }

    if (iteration.current < split.length + 4) {
      ++iteration.current;
      timed.run(type, { delay: ANIMATION_TYPE_SPEED_MS });
    } else {
      onFinishAnimation(container);
    }
  };

  const prepareContainerForAnimation = (container: HTMLElement) => {
    if (isAnimating) {
      if (iteration.current === 0) {
        container.innerHTML = '';
      }
      return;
    }

    // Set initial height
    container.style.setProperty(
      VARIABLES.HEIGHT,
      `${container.getBoundingClientRect().height}px`
    );
    container.classList.add(CLASSES.CONTAINER_ANIMATION);
    const maxHeight = getComputedStyle(container).maxHeight.match(/\d+/g);
    if (maxHeight?.length) {
      containerMaxHeight = +maxHeight[0];
    }
    setIsAnimating(true);
  };

  const onFinishAnimation = (container: HTMLElement) => {
    container.innerHTML = '';
    container.classList.remove(CLASSES.CONTAINER_ANIMATION);
    container.style.overflowY = '';
    iteration.current = 0;

    // Could not find a way to first clear the animation and then run the onAnimationEnd
    // callback
    setTimeout(() => {
      timed.cancel();

      onAnimationEnd?.();
      setIsAnimating(false);
    }, 1);
  };

  const cancel = () => {
    const container = document.getElementById(ANIMATION_CONTAINER_ID);
    if (!container) {
      return;
    }
    onFinishAnimation(container);
  };

  const animate = (value: string) => {
    splitText.current.original = value;
    splitText.current.split = value.split(' ');

    type();
  };

  return { animate, cancel, isAnimating };
};
