import type { FieldReadFunction, InMemoryCache } from '@apollo/client';
import { useApolloClient } from '@apollo/client';

import type {
  JobAggregationDiscipline,
  JobAggregationIndustry,
  JobAggregationResult,
} from '@xing-com/crate-common-graphql-types';

const sortAggregations =
  (type: 'discipline' | 'industry', variables?: Record<string, any>) =>
  (
    a: JobAggregationDiscipline | JobAggregationIndustry | null,
    b: JobAggregationDiscipline | JobAggregationIndustry | null
  ): number => {
    if (!a) return 1;
    if (!b) return -1;

    // This variable will be used in the `JobSearchByQuery` query
    const selectedFilters = variables?.query?.filter?.[type]?.id ?? [];
    const aIncluded = selectedFilters.includes(a.id);
    const bIncluded = selectedFilters.includes(b.id);

    // If one of the filters is selected and the other is not, the selected filter should come first
    if (aIncluded && !bIncluded) return -1;
    if (bIncluded && !aIncluded) return 1;

    // @ts-expect-error can't make `type` type work
    const aValue = a[type]?.localizationValue;
    // @ts-expect-error can't make `type` type work
    const bValue = b[type]?.localizationValue;

    // If none or all are selected, sort by name
    if (aValue && bValue) {
      return aValue.localeCompare(bValue);
    }

    return 0;
  };

type AggregationType = 'disciplines' | 'industries';

const sortedAggregations: {
  [K in AggregationType]: FieldReadFunction<JobAggregationResult[K]>;
} = {
  disciplines: (existing, { variables }) => {
    if (!existing) return existing;

    return existing.toSorted(sortAggregations('discipline', variables));
  },
  industries: (existing, { variables }) => {
    if (!existing) return existing;

    return existing.toSorted(sortAggregations('industry', variables));
  },
};

export const useAddTypePolicies = (): void => {
  const client = useApolloClient();

  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  (client.cache as InMemoryCache).policies.addTypePolicies({
    // Sort aggregations by selected filters and name
    JobAggregationResult: {
      fields: {
        ...sortedAggregations,
      },
    },

    // Disable aggregation normalisation, so the count field is always up to date
    JobAggregationBenefit: {
      keyFields: false,
    },
    JobAggregationCareerLevels: {
      keyFields: false,
    },
    JobAggregationCity: {
      keyFields: false,
    },
    JobAggregationCountry: {
      keyFields: false,
    },
    JobAggregationDiscipline: {
      keyFields: false,
    },
    JobAggregationEmploymentType: {
      keyFields: false,
    },
    JobAggregationIndustry: {
      keyFields: false,
    },
    JobAggregationRole: {
      keyFields: false,
    },
  });
};
