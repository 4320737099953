/* eslint-disable @typescript-eslint/no-explicit-any */
import { useQuery } from '@apollo/client';
import { useNavigate, useLocation } from '@reach/router';
import { useMemo, useEffect } from 'react';

import { SearchMode } from '@xing-com/crate-common-graphql-types';
import { useLoginState } from '@xing-com/crate-hooks-use-login-state';
import { SEARCH_PAGE_SIZE } from '@xing-com/crate-jobs-constants';
import { getJobSerpsSearchQueryVariables } from '@xing-com/crate-jobs-domain-serps-helpers';
import { useSerpsMatch } from '@xing-com/crate-jobs-domain-serps-hooks';
import type { JobSearchByQueryQuery } from '@xing-com/crate-jobs-graphql';
import { JobSearchByQueryDocument } from '@xing-com/crate-jobs-graphql';
import { useIsAiSearch, useSearchState } from '@xing-com/crate-jobs-hooks';
import { ROUTES } from '@xing-com/crate-jobs-paths';
import { useExperiment, useExperiments } from '@xing-com/hub';

import { getFiltersFromAggregations, getSearchCount } from '../helpers';

import { useSearchConsumer } from './use-search-consumer';

type UseSearchArgs = {
  onCompleted?: (data: JobSearchByQueryQuery) => void;
  isEmptyState?: boolean;
};

function sortObject(object: any): any {
  if (!object || typeof object !== 'object') {
    return object;
  }

  if (Array.isArray(object)) {
    return object.map((item) => sortObject(item));
  }

  const sortedObj: Record<string, any> = {};
  const keys = Object.keys(object).sort((key1, key2) => {
    key1 = key1.toLowerCase();
    key2 = key2.toLowerCase();
    if (key1 < key2) return -1;
    if (key1 > key2) return 1;
    return 0;
  });

  for (const key of keys) {
    sortedObj[key] = sortObject(object[key]);
  }

  return sortedObj;
}

// Return types are inferred
// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const useSearch = ({
  onCompleted,
  isEmptyState,
}: UseSearchArgs = {}) => {
  const { search } = useLocation();
  const navigate = useNavigate();
  const { isLoggedOut } = useLoginState();
  const consumer = useSearchConsumer();

  const serps = useSerpsMatch();
  const isSerps = serps && isLoggedOut;
  const serpsSearchQuery = isSerps
    ? getJobSerpsSearchQueryVariables(serps)
    : {};
  const { searchQuery, sort, page } = useSearchState();
  const isAiSearch = useIsAiSearch();
  const isMREnabled = useExperiment('ABACUS-484') === 'B';
  const { loading: isLoadingExperiments } = useExperiments();

  const finalKeywords = searchQuery.keywords || serpsSearchQuery.keywords;
  const finalLocation = searchQuery.location || serpsSearchQuery.location;
  const finalFilter = searchQuery.filter || serpsSearchQuery.filter;

  const mergedQuery = sortObject({
    ...searchQuery,
    ...(finalKeywords && { keywords: finalKeywords }),
    ...(finalLocation && { location: finalLocation }),
    ...(finalFilter && { filter: finalFilter }),
  });

  if (!isSerps && isEmptyState) {
    mergedQuery.location = undefined;
  }

  const { loading, data, previousData, fetchMore, refetch } = useQuery(
    JobSearchByQueryDocument,
    {
      variables: {
        query: mergedQuery,
        consumer,
        sort,
        limit: SEARCH_PAGE_SIZE,
        offset: SEARCH_PAGE_SIZE * (page - 1),
        trackRecent: !isEmptyState && !isAiSearch,
        matchingReasons: isMREnabled && isAiSearch,
        searchMode:
          isAiSearch && mergedQuery.keywords
            ? SearchMode.Semantic
            : SearchMode.Normal,
      },
      skip: isLoadingExperiments,
      errorPolicy: 'all',
      onCompleted,
      notifyOnNetworkStatusChange: true,
    }
  );

  const results = data?.jobSearchByQuery;
  const previousResults = previousData?.jobSearchByQuery;

  useEffect(() => {
    // if it's a SERP page, redirect to "search" needed on any change of sorting/filtering params
    if (isSerps && previousResults) {
      navigate(`${ROUTES.search}?${new URLSearchParams(search).toString()}`, {
        replace: true,
      });
    }
  }, [isSerps, search, navigate, previousResults]);

  const aggregations = useMemo(() => {
    const aggregations =
      results?.aggregations ?? previousResults?.aggregations ?? {};
    const filters = getFiltersFromAggregations(aggregations, isAiSearch);
    return { aggregations, filters };
  }, [results, previousResults, isAiSearch]);

  // We only want to use the previous results when the query is loading
  const filterCollection =
    results?.searchQuery.body?.filterCollection ??
    ((loading && previousResults?.searchQuery.body?.filterCollection) || []);

  const extractedFilters = useMemo(() => {
    const enabledFilters =
      results?.appliedFilters?.extractedFilters?.enabled ??
      ((loading &&
        previousResults?.appliedFilters?.extractedFilters?.enabled) ||
        []);

    const disabledFilters =
      results?.appliedFilters?.extractedFilters?.disabled ??
      ((loading &&
        previousResults?.appliedFilters?.extractedFilters?.disabled) ||
        []);

    return enabledFilters.filter((filter) => {
      if (!filter) return false;

      const isDisabled = disabledFilters.some((disabledFilter) => {
        if (!disabledFilter) return false;

        if (
          disabledFilter.__typename === 'JobFilterDisabledSalary' &&
          filter.__typename === 'JobFilterSalary'
        ) {
          return !!disabledFilter.salary;
        }

        if (
          disabledFilter.__typename === 'JobFilterLocation' &&
          filter.__typename === 'JobFilterLocation'
        ) {
          return (
            disabledFilter.location?.localizationValue ===
            filter.location?.localizationValue
          );
        }

        return (
          'entityId' in filter &&
          'entityId' in disabledFilter &&
          disabledFilter.entityId === filter.entityId &&
          disabledFilter.__typename === filter.__typename
        );
      });

      return !isDisabled;
    });
  }, [results, previousResults, loading]);

  return {
    aggregations,
    isLoading: loading,
    results,
    previousResults,
    filterCollection,
    extractedFilters,
    searchCount: results ? getSearchCount(results.searchQuery.guid) : undefined,
    fetchMore,
    refetch,
  };
};
