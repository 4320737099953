import { Helmet } from 'react-helmet-async';
import { useIntl } from 'react-intl';

export const SearchMetadata: React.FC = () => {
  const { formatMessage } = useIntl();

  const findJobsTitle = formatMessage({ id: 'JOBS_FIND_JOBS_TITLE' });

  return (
    <>
      <Helmet>
        <title>{`${findJobsTitle} - XING`}</title>
        <meta name="robots" content="noindex, follow" />
        <meta name="theme-color" content="#C6F16D" />
        {/* TODO: Check with CoP, missing PWA support, splash screen */}
        {/* <meta name="apple-mobile-web-app-capable" content="yes" /> */}
      </Helmet>
    </>
  );
};
