import type React from 'react';
import { useIntl } from 'react-intl';
import styled from 'styled-components';

import { useJoinFilters } from '@xing-com/crate-jobs-hooks';
import { lineClamp } from '@xing-com/crate-utils-line-clamp';
import { IconSearch } from '@xing-com/icons';
import {
  xdlColorBackground,
  scale005,
  xdlColorBorderStrong,
  xdlColorText,
  scale080,
  scale090,
  scale150,
} from '@xing-com/tokens';

import { useSearch } from '../../hooks';

export const FakeInput: React.FC = () => {
  const { formatMessage } = useIntl();
  const { filterCollection, results } = useSearch();

  const { keywords, location } = results?.searchQuery.body || {};
  const joinedFilters = useJoinFilters(filterCollection, {
    keywords: keywords,
    location: location?.city?.name || location?.text,
    radius: location?.radius,
  });

  const handleOnClick = (): void => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <Input
      onClick={handleOnClick}
      aria-label={formatMessage({ id: 'XDS_BACK_TO_TOP_BUTTON' })}
    >
      <Icon width={24} />
      <Text>{joinedFilters}</Text>
    </Input>
  );
};

const Input = styled.button`
  display: flex;
  gap: 10px;
  width: 80%;
  max-width: 700px;
  cursor: pointer;

  border-radius: calc(${scale150} / 2);
  font-size: ${scale080};
  height: ${scale150};
  padding-block-end: 10px;
  padding-block-start: 10px;
  padding-inline-end: ${scale090};
  padding-inline-start: ${scale090};

  background-color: ${xdlColorBackground};
  color: ${xdlColorText};
  border-color: transparent;
  border-style: solid;
  border-width: ${scale005};
  outline: none;

  &:focus {
    background-color: ${xdlColorBackground};
    border-color: ${xdlColorBorderStrong};
    border-width: ${scale005};
    box-shadow: inset 0 0 0 ${scale005} ${xdlColorBorderStrong};
    color: ${xdlColorText};
  }

  &:hover {
    background-color: ${xdlColorBackground};
    border-color: ${xdlColorBorderStrong};
    border-width: ${scale005};
    color: ${xdlColorText};
  }
`;

const Icon = styled(IconSearch)`
  flex-shrink: 0;
`;

const Text = styled.div`
  ${lineClamp(1)}
  text-align: left;
`;
