import { getAds } from '../helpers/get-ads';
import type { AdFields } from '../types';

import type { AdSettings } from './use-ads-network';
import { useAdsNetwork } from './use-ads-network';

export type UseCollectionType = {
  ads: AdFields[];
  isCompleted: boolean;
};

export const useAds = (settings: AdSettings): UseCollectionType => {
  const { data, loading, error } = useAdsNetwork(settings);
  const ads = getAds(data);
  const isCompleted = !loading && (data !== undefined || error !== undefined);

  return {
    ads,
    isCompleted,
  };
};
