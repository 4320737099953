import { FormattedMessage, FormattedPlural } from 'react-intl';

import { Headline } from '@xing-com/typography';

import { isVisibleJob } from '../../../helpers';
import { useSearch } from '../../../hooks';
import { LimitedJobCount } from '../../shared';
import { ResultsList } from '../results-list';

import { MoreRecommendations } from './more-recommendations';
import { MoreResultsSkeleton } from './more-results-skeleton';
import * as S from './more-results.styles';

export const MoreResults: React.FC = () => {
  const { results, isLoading } = useSearch({ isEmptyState: true });
  const searchResultsTotal = results?.total ?? 0;
  const collectionLength = results?.collection.length ?? 0;

  if (isLoading) return <MoreResultsSkeleton />;
  if (collectionLength === 0) return <MoreRecommendations />;

  const collection = results?.collection.filter(isVisibleJob);
  if (!collection) return null;

  const slugList = collection.map((item) => item.jobDetail.slug);

  return (
    <S.Container>
      <Headline size="medium" sizeWide="large">
        <FormattedPlural
          value={collectionLength}
          one={
            <FormattedMessage id="JOBS_SEARCH_EMPTY_STATE_WIDER_RESULTS_HEADER.one" />
          }
          other={
            <FormattedMessage
              id="JOBS_SEARCH_EMPTY_STATE_WIDER_RESULTS_HEADER.other"
              values={{
                variable_2:
                  searchResultsTotal > 0 ? (
                    <LimitedJobCount count={searchResultsTotal} />
                  ) : null,
              }}
            />
          }
        />
      </Headline>
      <ResultsList
        items={collection}
        type="EMPTY_STATE_SEARCH"
        slugList={slugList}
      />
    </S.Container>
  );
};
