import { FormattedMessage } from 'react-intl';

import { LGapCol } from '@xing-com/crate-jobs-components-columns';
import {
  MAX_SALARY,
  MIN_SALARY,
  SALARY_STEP,
} from '@xing-com/crate-jobs-constants';
import { Slider } from '@xing-com/slider';
import { BodyCopy, Headline } from '@xing-com/typography';

import { SalaryValue } from '../../shared';
import { SliderWrapper } from '../shared.styles';

import { useSalaryFilter } from './use-salary';

export const BottomSheetSalaryFilter: React.FC = () => {
  const { value, onChange, onCommit, getAriaLabel } = useSalaryFilter();

  return (
    <div role="group" aria-labelledby="salary-filter-title">
      <Headline size="xxlarge" noMargin id="salary-filter-title">
        <FormattedMessage id="JOBS_SEARCH_FILTER_SALARY" />
      </Headline>
      <LGapCol>
        <BodyCopy noMargin>
          <SalaryValue min={value[0]} max={value[1]} />
        </BodyCopy>
        <SliderWrapper>
          <Slider
            max={MAX_SALARY}
            min={MIN_SALARY}
            step={SALARY_STEP}
            value={value}
            onChange={onChange}
            onChangeCommitted={onCommit}
            getAriaLabel={getAriaLabel}
          />
        </SliderWrapper>
      </LGapCol>
    </div>
  );
};
